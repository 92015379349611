<template>
  <div class="m-2">
    <b-card title="Transaction Basic Info">
      <b-row>
        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Transaction ID :
            </dt>
            <dd class="col-sm-7">
              {{ tradeNo }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Merchant Reference :
            </dt>
            <dd class="col-sm-7">
              {{ outTradeNo }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Method :
            </dt>
            <dd class="col-sm-7">
              {{ baseInfo.method }}
            </dd>
          </dl>
        </b-col>

        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Status :
            </dt>
            <dd class="col-sm-7">
              {{ baseInfo.trade_status }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Order Amount :
            </dt>
            <dd class="col-sm-7">
              {{ baseInfo.order_currency }} {{ baseInfo.order_amount }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Pay Amount :
            </dt>
            <dd class="col-sm-7">
              {{ baseInfo.pay_currency }} {{ baseInfo.pay_amount }}
            </dd>
          </dl>
        </b-col>

        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Create Time :
            </dt>
            <dd class="col-sm-7">
              {{ timeZoneFilter(baseInfo.create_time) }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Update Time :
            </dt>
            <dd class="col-sm-7">
              {{ timeZoneFilter(baseInfo.update_time) }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Endpoint :
            </dt>
            <dd class="col-sm-7">
              {{ version }} - {{ endpoint }}
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-button class="ml-2" variant="outline-danger" @click="callbackPrompt">
          <feather-icon
            icon="ExternalLinkIcon"
          />
          Callback
        </b-button>
        <b-button v-if="baseInfo.trade_status !== 'RiskControlAML' && ($can('write', 'Transaction.List') || $can('delete', 'Transaction.List'))" class="ml-1" variant="outline-danger" @click="fillOrderPrompt">
          <feather-icon
            icon="EyeIcon"
          />
          FillOrder
        </b-button>
      </b-row>
    </b-card>

    <b-row>
      <b-col md="6">
        <b-card title="Merchant">
          <dl class="row">
            <dt class="col-sm-4">
              Merchant Name :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.merchant_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Merchant NO. :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.merchant_no }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              APP Name :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.app_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              APP ID :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.app_id }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              User ID :
            </dt>
            <dd class="col-sm-8">
              {{ userInfo.buyer_id }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Product">
          <dl class="row">
            <dt class="col-sm-4">
              Subject :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.subject }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Content :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.content }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Notify URL :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.notify_url }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Return URL :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.return_url }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Buyer">
          <dl v-if="userInfo.identify" class="row">
            <dt class="col-sm-4">
              Identify :
            </dt>
            <dd class="col-sm-8">
              {{ userInfo.identify.type || 'Default' }} - {{ userInfo.identify.number }}
            </dd>
          </dl>
          <dl v-if="userInfo.identification" class="row">
            <dt class="col-sm-4">
              Identification :
            </dt>
            <dd class="col-sm-8">
              {{ userInfo.identification.type || 'Default' }} - {{ userInfo.identification.number }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Name :
            </dt>
            <dd class="col-sm-8">
              {{ userInfo.username }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Phone :
            </dt>
            <dd class="col-sm-8">
              {{ userInfo.phone }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Email :
            </dt>
            <dd class="col-sm-8">
              {{ userInfo.email }}
            </dd>
          </dl>
          <dl v-if="paymentInfo.zip_code" class="row">
            <dt class="col-sm-4">
              ZipCode :
            </dt>
            <dd class="col-sm-8">
              {{ paymentInfo.zip_code }}
            </dd>
          </dl>
          <dl v-if="paymentInfo.user_ip" class="row">
            <dt class="col-sm-4">
              IP :
            </dt>
            <dd class="col-sm-8">
              {{ paymentInfo.user_ip }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col v-if="payer" md="6">
        <b-card title="Payer">
          <dl v-if="payer.identification" class="row">
            <dt class="col-sm-4">
              Identification :
            </dt>
            <dd class="col-sm-8">
              {{ payer.identification.type || 'Default' }} - {{ payer.identification.number }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Name :
            </dt>
            <dd class="col-sm-8">
              {{ payer.username }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col v-if="channelInfo" md="6">
        <b-card title="Channel Information">
          <dl class="row">
            <dt class="col-sm-4">
              ID :
            </dt>
            <dd class="col-sm-8">
              {{ channelInfo.channel_id }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Name :
            </dt>
            <dd class="col-sm-8">
              {{ channelInfo.channel_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Order ID :
            </dt>
            <dd class="col-sm-8">
              {{ channelInfo.channel_order_id }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Order Status :
            </dt>
            <dd class="col-sm-8">
              {{ channelInfo.channel_status }}
            </dd>
          </dl>

          <div>
            <el-divider />

            <b-button v-if="$can('write', 'Transaction.List')" variant="flat-warning" class="mr-1" @click="cancelConfirm">
              <feather-icon
                icon="ZapOffIcon"
              />
              Cancel
            </b-button>
            <b-button variant="flat-warning" @click="recheckOrder">
              <feather-icon
                icon="SearchIcon"
              />
              Recheck
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Fees">
          <dl class="row">
            <dt class="col-sm-4">
              Additional :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.pay_currency }} {{ baseInfo.additional_fee }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Discountable :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.pay_currency }} {{ baseInfo.discountable_amount }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Processing :
            </dt>
            <dd class="col-sm-8">
              {{ baseInfo.pay_currency }} {{ baseInfo.processing_fee_amount }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col v-if="baseInfo.method == 'DepositExpress' && voucher" md="6">
        <b-card title="Voucher">
          <dl v-if="voucher.payment_voucher" class="row">
            <dt class="col-sm-4">
              Payment Voucher :
            </dt>
            <dd class="col-sm-4">
              {{ voucher.payment_voucher.time | timestampFilter }}
            </dd>
            <dd class="col-sm-4">
              <a target="_blank" :href="voucher.payment_voucher.url">
                Download
              </a>
            </dd>
          </dl>
          <dl v-if="voucher.personal_id" class="row">
            <dt class="col-sm-4">
              Personal I.D. :
            </dt>
            <dd class="col-sm-4">
              {{ voucher.personal_id.time | timestampFilter }}
            </dd>
            <dd class="col-sm-4">
              <a target="_blank" :href="voucher.personal_id.url">
                Download
              </a>
            </dd>
          </dl>
          <dl v-if="voucher.addr_proof" class="row">
            <dt class="col-sm-4">
              Proof of Address :
            </dt>
            <dd class="col-sm-4">
              {{ voucher.addr_proof.time | timestampFilter }}
            </dd>
            <dd class="col-sm-4">
              <a target="_blank" :href="voucher.addr_proof.url">
                Download
              </a>
            </dd>
          </dl>

          <div v-if="voucher.upload_url">
            <el-divider class="mt-1 mb-1" />

            <a target="_blank" :href="voucher.upload_url">
              <b-button variant="flat-warning">
                <feather-icon
                  icon="UploadIcon"
                />
                Upload
              </b-button>
            </a>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="cardInfo && cardInfo.first_six_digits && cardInfo.first_six_digits !== '' && cardInfo.last_four_digits && cardInfo.last_four_digits !== ''" md="6">
        <b-card title="Card Information">
          <dl class="row">
            <dt class="col-sm-4">
              Card NO. :
            </dt>
            <dd class="col-sm-8">
              {{ cardInfo.first_six_digits }}******{{ cardInfo.last_four_digits }}
            </dd>
          </dl>
          <dl v-if="cardInfo.holder" class="row">
            <dt class="col-sm-4">
              Holder :
            </dt>
            <dd class="col-sm-8">
              {{ cardInfo.holder.name }}
            </dd>
          </dl>

          <div>
            <el-divider />

            <b-button v-if="$can('write', 'Transaction.List')" variant="flat-warning" class="mr-1" @click="revealCard">
              <feather-icon
                icon="EyeIcon"
              />
              Reveal
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="boletoInfo" md="6">
        <b-card title="Boleto Information">
          <!-- <dl class="row">
            <dt class="col-sm-4">
              Barcode :
            </dt>
            <dd class="col-sm-8">
              {{ boletoInfo.barcode }}
            </dd>
          </dl> -->
          <dl class="row">
            <dt class="col-sm-4">
              Barcode :
            </dt>
            <dd class="col-sm-8">
              {{ boletoInfo.bank_code }}
            </dd>
          </dl>
          <dl v-if="boletoInfo.pay_url" class="row">
            <dt class="col-sm-4">
              Boleto Image :
            </dt>
            <dd class="col-sm-8">
              <a :href="boletoInfo.pay_url" target="_blank">
                <feather-icon
                  icon="EyeIcon"
                />
                View
              </a>
            </dd>
          </dl>
          <dl v-if="boletoInfo.boleto_url" class="row">
            <dt class="col-sm-4">
              Original Boleto Image :
            </dt>
            <dd class="col-sm-8">
              <a :href="boletoInfo.boleto_url" target="_blank">
                <feather-icon
                  icon="EyeIcon"
                />
                View
              </a>
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col v-if="lotteryInfo" md="6">
        <b-card title="Lottery Information">
          <dl class="row">
            <dt class="col-sm-4">
              Barcode :
            </dt>
            <dd class="col-sm-8">
              {{ lotteryInfo.barcode }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col v-if="historyInfo" md="6">
        <b-card title="History">
          <dl v-for="(value, key) in historyInfo" :key="key" class="row">
            <dt class="col-sm-4">
              {{ value.replace('notify_', '') }} :
            </dt>
            <dd class="col-sm-8">
              {{ timeZoneFilter(key) }}
            </dd>
          </dl>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card title="Activity">
          <b-table
            class="position-relative"
            responsive
            :fields="transLogTableFields"
            :items="logInfo"
            show-empty
            striped
            :borderless="false"
            empty-text="No matching records found"
          >

            <!-- Column: Create Time -->
            <template #cell(amount)="data">
              {{ data.item.currency }} {{ data.item.amount }}
            </template>

            <!-- Column: Create Time -->
            <template #cell(actual_amount)="data">
              {{ data.item.actual_currency }} {{ data.item.actual_amount }}
            </template>

            <!-- Column: Create Time -->
            <template #cell(create_time)="data">
              {{ timeZoneFilter(data.item.create_time) }}
            </template>

            <!-- Column: Actions -->
            <template #cell(opt)="data">
              <div v-if="data.item.log_status == 'OpenRefund'" class="text-nowrap">
                <feather-icon icon="XIcon" class="cursor-pointer" size="16" @click="revokeRefundConfirm(data.item)" />
              </div>
              <div v-if="data.item.log_status == 'RefundProcessing'" class="text-nowrap">
                <feather-icon icon="CheckIcon" class="cursor-pointer" size="16" @click="approveRefundConfirm(data.item)" />
                <feather-icon icon="XIcon" class="cursor-pointer ml-1" size="16" @click="refundRejectionPrompt(data.item)" />
                <feather-icon icon="FileTextIcon" class="cursor-pointer ml-1" size="16" @click="displayPayeeInformation(data.item)" />
              </div>
              <div v-if="data.item.log_status == 'RefundRefused'" class="text-nowrap">
                <feather-icon icon="FileTextIcon" class="cursor-pointer ml-1" size="16" @click="displayPayeeInformation(data.item)" />
              </div>
            </template>

            <!-- Column: desc -->
            <template #cell(log_status)="data">
              <span v-b-tooltip.hover.left.v-info :title="data.item.description">
                {{ data.item.log_status }}
              </span>
            </template>

            <!-- Column: desc -->
            <template #cell(log_callback_status)="data">
              <b-link
                class="font-weight-bold d-block"
                @click="showCallbackHistory(data.item.callback_history)"
              >
                {{ data.item.log_callback_status }}
              </b-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <!-- 风控 & AML -->
    <b-row>
      <b-col v-if="riskInfo" md="6">
        <b-card-actions title="Risk Information" action-collapse>
          <dl class="row">
            <dt class="col-sm-3">
              Payment Previous Page :
            </dt>
            <dd class="col-sm-9">
              {{ riskInfo.payment_prev_url }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Checkout URL :
            </dt>
            <dd class="col-sm-9">
              {{ riskInfo.original_source }}
            </dd>
          </dl>
          <dl v-for="(value, key) in riskInfoDetail" :key="key" class="row">
            <dt v-if="key === 'Final Result'" class="col-sm-3" style="font-weight:bold">
              {{ key }} :
            </dt>
            <dt v-else class="col-sm-3">
              {{ key }} :
            </dt>
            <dd class="col-sm-9">
              {{ value }}
            </dd>
          </dl>
        </b-card-actions>
      </b-col>
      <b-col v-if="amlInfo" md="6">
        <b-card-actions title="AML Information" action-collapse>
          <dl class="row">
            <dt class="col-sm-3">
              Pre Result :
            </dt>
            <dd class="col-sm-9">
              {{ amlInfo.aml_pre }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Post Result :
            </dt>
            <dd class="col-sm-9">
              {{ amlInfo.aml_post }}
            </dd>
          </dl>
        </b-card-actions>
      </b-col>
      <b-col v-if="riskInfo" md="6">
        <b-card-actions title="Bank Response" action-collapse>
          <dl class="row">
            <dt class="col-sm-3">
              Message :
            </dt>
            <dd class="col-sm-9">
              {{ riskInfo.message }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Refuse Info :
            </dt>
            <dd class="col-sm-9">
              {{ riskInfo.refuse_info }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Error Message :
            </dt>
            <dd class="col-sm-9">
              {{ riskInfo.error_msg }}
            </dd>
          </dl>
          <dl v-if="baseInfo.trade_status === 'ChargeBack'" class="row">
            <dt class="col-sm-3">
              Chargeback Reason :
            </dt>
            <dd class="col-sm-9">
              {{ payRespInfo.chargeback_reason }}
            </dd>
          </dl>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card title="Operation Logs">
          <b-table
            class="position-relative"
            responsive
            :fields="opLogFields"
            :items="operationLogs"
            show-empty
            striped
            :borderless="false"
            empty-text="No matching records found"
          >
            <template #cell(download_url)="data">
              <span v-if="data.item.download_url !== ''">
                <a
                  target="_blank"
                  :href="data.item.download_url"
                >
                  <b-button variant="flat-primary">
                    Download
                  </b-button>
                </a>
              </span>
            </template>
            <template #cell(create_time)="data">
              {{ timeZoneFilter(data.item.create_time) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card-actions title="Payment" action-collapse>
          <dl v-for="(value, key) in paymentInfo" :key="key" class="row">
            <dt class="col-sm-2">
              {{ key }} :
            </dt>
            <dd class="col-sm-10">
              {{ value }}
            </dd>
          </dl>
        </b-card-actions>
      </b-col>
      <b-col md="12">
        <b-card-actions title="Pay Response" action-collapse>
          <dl v-for="(value, key) in payRespInfo" :key="key" class="row">
            <dt class="col-sm-2">
              {{ key }} :
            </dt>
            <dd class="col-sm-10">
              {{ value }}
            </dd>
          </dl>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-modal
      id="modal-bank-info"
      ref="modalBankInfo"
      title="Check Refund Bank Info"
      ok-only
      ok-title="OK"
      centered
      size="xl"
      @hidden="bankInfo = ''"
    >
      <div class="text-wrapper">
        {{ bankInfo }}
      </div>
    </b-modal>

    <b-modal
      id="modal-callback-history"
      ref="modalCallbackHistory"
      title="Check Callback History"
      ok-only
      ok-title="OK"
      centered
      @hidden="callbackHistory = ''"
    >
      <b-list-group>
        <b-list-group-item v-for="(value, key) in callbackHistory" :key="key">
          {{ value }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal
      id="modal-fill-order"
      ref="modalFillOrder"
      title="Mock Transaciont Status"
      ok-title="Accept"
      centered
      @hidden="resetFillOrderInfo"
      @ok="fillOrder"
    >
      <b-form ref="checkForm" @submit.stop.prevent="handleSubmit">
        <b-form-group label="New Status" label-for="fill-new-status">
          <v-select
            v-model="fillStatus"
            :options="statusOptions"
            :clearable="false"
            :searchable="false"
            :reduce="val => val.value"
            input-id="fill-new-status"
          />
        </b-form-group>
        <b-form-group>
          <label for="fill-channel-order">Pay Channel Order(if exist)</label>
          <b-form-input
            id="fill-channel-order"
            v-model="fillPayChannelOrder"
            oninput="if(value.length > 64)value = value.slice(0, 64)"
          />
        </b-form-group>
        <b-form-group>
          <label for="fill-channel-order-status">Pay Channel Order Status(if exist)</label>
          <b-form-input
            id="fill-channel-order-status"
            v-model="fillPayChannelOrderStatus"
            oninput="if(value.length > 32)value = value.slice(0, 32)"
          />
        </b-form-group>
        <b-form-group>
          <label for="fill-payer-id">Payer Identification(if exist)</label>
          <b-form-input
            id="fill-payer-id"
            v-model="fillPayerId"
            oninput="if(value.length > 32)value = value.slice(0, 32)"
          />
        </b-form-group>
        <b-form-group v-if="fillStatus === '20000'">
          <label>Payment Receipt</label>
          <b-form-file
            id="fill-file-id"
            v-model="uploadFiles"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            invalid-feedback="Name is required"
            multiple
          />
        </b-form-group>
        <b-form-group>
          <label for="fill-password">Password</label>
          <b-form-input
            id="fill-password"
            v-model="fillPassword"
            type="password"
          />
        </b-form-group>
        <b-form-checkbox v-model="fillSwitch">
          For Real
        </b-form-checkbox>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-reject-refund"
      ref="modalRejectRefund"
      title="Refund Rejection"
      ok-title="Confirm"
      centered
      @ok="rejectRefundConfirm"
    >
      <b-form>
        <b-form-group label="Reason" label-for="reject-reason-options">
          <v-select
            v-model="rejectReason"
            :options="rejectReasonOptions"
            :clearable="false"
            :searchable="false"
            input-id="reject-reason-options"
          />
        </b-form-group>
        <b-form-group>
          <label for="reject-reason-detail">Reason Detail</label>
          <b-form-input
            id="reject-reason-detail"
            v-model="rejectReasonDetail"
            oninput="if(value.length > 128)value = value.slice(0, 128)"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCard, BRow, BCol, BButton, BTable, BForm, BFormGroup, BFormCheckbox, BFormInput, VBTooltip, BLink, BListGroup, BListGroupItem, BFormFile, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import JSEncrypt from 'jsencrypt'
import { showToast, showAlert } from '@/libs/tool'
import useTransactionsJs from './transactions'

const {
  fetchTransactionDetail,
  revokeRefund,
  refundConfirm,
  callback,
  fillOrder,
  cancelOrder,
  recheckOrder,
  renotifyOrder,
  revealCard,
} = useTransactionsJs()

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BLink,
    BListGroup,
    BListGroupItem,
    vSelect,
    BFormFile,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    timestampFilter(timestamp) {
      return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  data() {
    return {
      outTradeNo: this.$route.query.out_trade_no,
      appId: this.$route.query.app_id,
      timeZone: this.$route.query.zone_id,
      tradeNo: '',
      baseInfo: {},
      version: '',
      endpoint: '',
      voucher: {},
      riskInfo: {},
      riskInfoDetail: {},
      amlInfo: {},
      cardInfo: null,
      boletoInfo: {},
      lotteryInfo: {},
      channelInfo: {},
      logInfo: [],
      operationLogs: [],
      userInfo: {},
      payer: null,
      paymentInfo: {},
      payRespInfo: {},
      cardRevealable: false,
      bankInfo: '',
      historyInfo: {},
      callbackHistory: [],
      transLogTableFields: [
        'type',
        'out_request_no',
        'amount',
        'actual_amount',
        'log_status',
        'log_callback_status',
        'create_time',
        'opt',
      ],
      opLogFields: [
        { key: 'operation', label: 'operation' },
        { key: 'user_name', label: 'user name' },
        { key: 'create_time', label: 'create time' },
        { key: 'download_url', label: 'file' },
      ],
      fillStatus: '',
      fillPayChannelOrder: '',
      fillPayChannelOrderStatus: '',
      fillPayerId: '',
      fillPassword: '',
      uploadFiles: [],
      fillSwitch: false,
      statusOptions: [
        { label: 'Initial', value: '10001' },
        { label: 'Processing', value: '18000' },
        { label: 'Success', value: '20000' },
        { label: 'Refused', value: '21000' },
        { label: 'Refunded', value: '22000' },
        { label: 'Dispute', value: '30000' },
        { label: 'ChargeBack', value: '23000' },
        { label: 'ChargebackReversed', value: '23001' },
        { label: 'Cancelled', value: '25000' },
        { label: 'Expired', value: '26000' },
        { label: 'RiskControl', value: '27000' },
      ],
      rejectReason: '',
      rejectReasonDetail: '',
      loading: false,
      rejectReasonOptions: [
        'Bank Refused',
        'Bank Rejected',
        'User Info Incorrect',
        'Timeout',
      ],
      rejectedRefundItem: {},
    }
  },
  watch: {
    rejectReason: function fillRejectReason(reason) {
      this.rejectReasonDetail = reason
    },
  },
  created() {
    const params = {
      app_id: this.appId,
      out_trade_no: this.outTradeNo,
    }
    fetchTransactionDetail(
      params,
      data => {
        this.tradeNo = data.detail.trade_no
        this.outTradeNo = data.detail.out_trade_no
        this.version = data.detail.version
        this.endpoint = data.detail.endpoint

        this.baseInfo = data.detail.trans_base
        this.voucher = data.voucher
        this.riskInfo = data.risk
        this.amlInfo = data.aml
        this.cardInfo = data.card_info
        this.boletoInfo = data.boleto
        this.lotteryInfo = data.lottery
        this.channelInfo = data.channel_info
        this.historyInfo = data.history

        if (this.baseInfo.user_info) {
          this.userInfo = this.baseInfo.user_info
        }
        if (this.baseInfo.payer) {
          this.payer = this.baseInfo.payer
        }
        if (this.baseInfo.payment_info) {
          this.paymentInfo = this.baseInfo.payment_info
        }
        if (this.baseInfo.pay_resp_info) {
          this.payRespInfo = this.baseInfo.pay_resp_info
        }
        if (this.baseInfo.pay_resp_info) {
          this.payRespInfo = this.baseInfo.pay_resp_info
        }
        this.logInfo = data.detail.trans_logs

        this.operationLogs = data.operations
        if (data.risk.risk_detail) {
          this.riskInfoDetail = data.risk.risk_detail
        }
      },
      fail => {
        showToast(this, 'Warning', `Fetch transaction detail failed with ${fail}`)
      },
    )
  },
  methods: {
    checkFormValidity() {
      if (this.fillStatus === '20000' && this.uploadFiles.length === 0) {
        showToast(this, 'Warning', 'OP Fail!! You should upload the file.')
        return false
      }
      if (this.baseInfo.method !== 'SPEI' && this.fillPayChannelOrderStatus.length > 29) {
        showToast(this, 'Warning', 'OP Fail!! Pay Channel Order Status length error.')
        return false
      }
      return true
    },
    timeZoneFilter(dt) {
      if (!dt) {
        return ''
      }
      return moment.tz(moment.tz(dt, 'UTC'), this.timeZone).format('YYYY-MM-DD HH:mm:ss')
    },
    callbackPrompt() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const params = {
            app_id: this.baseInfo.app_id,
            out_trade_no: this.outTradeNo,
          }
          callback(
            params,
            data => {
              showToast(this, 'Success', `Callback success and merchant return ${data}`)
            },
            fail => {
              showToast(this, 'Warning', `Callback failed with ${fail}`)
            },
          )
        }
      })
    },
    fillOrderPrompt() {
      this.$refs.modalFillOrder.show()
    },
    refundRejectionPrompt(item) {
      this.rejectedRefundItem = item
      this.resetRejectRefund()
      this.$refs.modalRejectRefund.show()
    },
    fillOrder() {
    // fillOrder(bvModalEvent) {
      // bvModalEvent.preventDefault()
      // if (!this.checkFormValidity()) {
      //   return
      // }
      const params = {
        app_id: this.baseInfo.app_id,
        out_trade_no: this.outTradeNo,
        trade_status: this.fillStatus,
        pay_channel_order: this.fillPayChannelOrder,
        partner_status: this.fillPayChannelOrderStatus,
        identification: {
          type: '',
          number: this.fillPayerId,
        },
        pwd: this.fillPassword,
        live_mode: this.fillSwitch,
      }
      // const formData = new FormData()
      // if (this.fillStatus === '20000') {
      //   if (this.uploadFiles.length === 0) {
      //     showToast(this, 'Warning', 'OP Fail!! You should upload the file.')
      //     return
      //   }
      //   for (let i = 0; i < this.uploadFiles.length; i += 1) {
      //     const copyFile = new File([this.uploadFiles[i]], `${this.tradeNo}_${this.uploadFiles[i].name}`)
      //     formData.append('file', copyFile)
      //   }
      // }
      // formData.append('data', JSON.stringify(params))
      this.loading = true
      fillOrder(
        params,
        () => {
          this.loading = false
          showToast(this, 'Success', 'fill success.')
          this.$nextTick(() => {
            this.$bvModal.hide('modal-fill-order')
          })
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fill order failed with ${fail}`)
        },
      )
    },
    resetFillOrderInfo() {
      this.fillStatus = ''
      this.fillPayChannelOrder = ''
      this.fillPayChannelOrderStatus = ''
      this.fillPassword = ''
      this.fillSwitch = false
      this.uploadFiles = []
    },
    resetRejectRefund() {
      this.rejectReason = ''
      this.rejectReasonDetail = ''
    },
    revokeRefundConfirm(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const params = {
            trade_no: this.tradeNo,
            app_id: this.baseInfo.app_id,
            out_trade_no: this.outTradeNo,
            out_request_no: item.out_request_no,
          }
          revokeRefund(
            params,
            () => {
              showToast(this, 'Success', 'revoke success.')
            },
            fail => {
              showToast(this, 'Warning', `revoke failed with ${fail}`)
            },
          )
        }
      })
    },
    approveRefundConfirm(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const params = {
            opt: 1,
            trade_no: this.tradeNo,
            app_id: this.baseInfo.app_id,
            out_trade_no: this.outTradeNo,
            out_request_no: item.out_request_no,
            reason: '',
          }
          refundConfirm(
            params,
            () => {
              showToast(this, 'Success', 'Refund success.')
            },
            fail => {
              showToast(this, 'Warning', `failed with ${fail}`)
            },
          )
        }
      })
    },
    rejectRefundConfirm() {
      console.log(this.rejectReasonDetail)
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const params = {
            opt: 0,
            trade_no: this.tradeNo,
            app_id: this.baseInfo.app_id,
            out_trade_no: this.outTradeNo,
            out_request_no: this.rejectedRefundItem.out_request_no,
            reason: this.rejectReasonDetail,
          }
          refundConfirm(
            params,
            () => {
              showToast(this, 'Success', 'reject Refund success.')
            },
            fail => {
              showToast(this, 'Warning', `reject failed with ${fail}`)
            },
          )
        }
      })
    },
    displayPayeeInformation(item) {
      this.bankInfo = item.bank_info
      this.$refs.modalBankInfo.show()
    },
    cancelConfirm() {
      this.$swal({
        title: 'Are you sure?',
        text: 'This will send a request to Bank to CANCEL this order!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.cancelOrder()
        }
      })
    },
    cancelOrder() {
      cancelOrder(
        this.tradeNo,
        () => {
          showToast(this, 'Success', 'Submitted.')
        },
        fail => {
          showToast(this, 'Warning', `cancel fail with ${fail}.`)
        },
      )
    },
    recheckOrder() {
      const params = {
        trade_no: this.tradeNo,
        app_id: this.baseInfo.app_id,
        out_trade_no: this.outTradeNo,
      }
      recheckOrder(
        params,
        success => {
          this.$swal({
            title: 'Results',
            text: `${success}`,
            showCancelButton: true,
            confirmButtonText: 'Renotify',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              renotifyOrder(
                params,
                renotified => {
                  showAlert(this, 'success', 'Success', `${renotified}`)
                },
                fail => {
                  showToast(this, 'Warning', `renotify fail with ${fail}.`)
                },
              )
            }
          })
        },
        fail => {
          showToast(this, 'Warning', `recheck fail with ${fail}.`)
        },
      )
    },
    showCallbackHistory(historyList) {
      this.callbackHistory = historyList
      this.$refs.modalCallbackHistory.show()
    },
    revealCard() {
      const params = {
        app_id: this.appId,
        out_trade_no: this.outTradeNo,
      }
      revealCard(
        params,
        card => {
          const decrypt = new JSEncrypt()
          decrypt.setPrivateKey('MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAInp4/+52CVki+FB6h4wuhtpvk17YTX2+9d1CkkAqmpMCQKQjauMuh+8wG+CRAxMvrfKDimpAHFmlnsu8uvu1SY5q5Du2apFrDTFcctd9bnH+xylq8RgGHJPfv6woZM+3qtd5HPAFw216HGceIYrB0R8cgP73RLxDm6JECKSd5oLAgMBAAECgYB4bjsAG7yI4qVXniSgGnLZ71wT8xKvei7vNbA2kZM+4YjYqXxc+rWpcz0j4sAjcXz1p0b40DCn1irABP5yUovj3i0WiI8QlPA0FgUUUpaGPIWGSXGm4adqBXeMwCM5YOt6qvenSYBsH96m4k0FFfktLY2cKAVrXiSsnukQOU36uQJBAL+Kvs54HHiiOhk6LvmaVYVkoCzkgee/On0UNOXPuhqtHA8Rct64EBCNwln+ZHTSzwc2BRcu4yWQm29E7rBftbUCQQC4UxaNDmCm/9aU3JL6MH1K0LKTcrxrLYwO8TVpSPJEW2CtOxqa+OUWIEPVqmDlgiiaqJgfMm2HHPURa9Yyt+i/AkB0OmT+NLRSH17R4BSRMa2jfCQWGbMaRVfgflp6+Uoc1d6o5hWmQFq/MMY2hb+/hAxMe+Mq5jC/orgvFM0iCXHhAkAG6n8vHbn8qh7ICRquroEU/I2mwKhXMSdPLDe/0o/chr0y4sPVyr71vJIvAyyNj/usCeVjTkYTyr9bPvuTfZkLAkBNduR1mAhbhOlyaK6TZTsgTs0KOiykhn43mmSOOmoVBAIUGuTYnHrwZLE7M/vWU+zlXp+yR5kzX6xmb/LApmpk')
          const cardNo = decrypt.decrypt(card)
          showAlert(this, 'success', 'Success', `${cardNo}`)
        },
        fail => {
          showToast(this, 'Warning', `cancel fail with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.text-wrapper {
  word-break: break-all;
  word-wrap: break-word
}
</style>
